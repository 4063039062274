@import 'easing';

@font-face {
  font-family: "Monument Grotesk";
  font-weight: 500;
  src: url(../fonts/MonumentGrotesk-Medium.woff2) format('woff2'),
       url(../fonts/MonumentGrotesk-Medium.woff) format('woff2');
}

@font-face {
  font-family: "Monument Grotesk";
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/MonumentGrotesk-MediumItalic.woff2) format('woff2'),
       url(../fonts/MonumentGrotesk-MediumItalic.woff) format('woff2');
}

@mixin non-mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin touch {
  @media screen and (any-pointer: coarse) {
    @content;
  }
}

@mixin mouse {
  @media screen and (any-pointer: fine) {
    @content;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5;
}

html {
  font-size: 16px;
  font-family: "Monument Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
ol,
ul,
table {
  margin-top: 0;
  margin-bottom: 1.1em;
  max-width: 50vw;

  &:last-child {
    margin-bottom: 0;
  }

  @include phone {
    max-width: 100%;
  }
}

ol,
ul {
  padding-left: 1em;
}

a {
  color: inherit;

  &:hover {
    font-style: italic;
  }
}

.image-ratio,
.icon-ratio {
  position: relative;
  
  img,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

svg {
  fill: currentColor;
  vertical-align: middle;
  
  use {
    pointer-events: none;
  }
}

figcaption {
  padding: 2px;
}

label {
  font-size: 13px;
  text-size-adjust: 100%;
}

fieldset {
  border: none;
  margin: 0 0 32px;
}

legend {
  margin-bottom: 4.5px;
}

input,
button,
.button {
  appearance: none;
  border-radius: 999px;
  color: inherit;
  background: none;
  border: 1px solid;
  padding: 5px 12px;
  font-family: inherit;
  font-size: inherit;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 300px;
}

input[type="number"] {
  width: 62px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
}

input[type="checkbox"],
input[type="radio"] {
  height: 31px;
  width: 53px;
}

.honigpferd {
  position: absolute;
  left: -9999px;
  top: -99999px;
}

button,
.button {
  cursor: pointer;
  background: black;
  color: white;
  padding: 5px 20px;
  text-decoration: none;

  &:hover {
    font-style: italic;
  }
}

.form-group {
  margin-bottom: 9px;

  label {
    display: block;
    margin-bottom: 1px;
  }

  button {
    margin-top: 10px;
  }
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -4.5px;
  margin-top: 4.5px;

  .form-group {
    padding: 4.5px;
    flex-basis: 100%;
    margin-bottom: 0;

    &[data-width="1/3"] {
      flex-basis: calc(100% / 3);
    }

    &[data-width="2/3"] {
      flex-basis: calc(100% / 3 * 2);
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      width: 100%;
    }
  }
}

.form-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include phone {
    margin-bottom: 32px;
  }

  a {
    text-decoration: none;
  }
}

.checkbox-replacement {
  position: relative;
  display: flex !important;
  align-items: center;

  input {
    margin-right: 9px;

    &:checked {
      ~ .icon-checkmark {
        display: initial;
      }
    }
  }

  .icon {
    width: 18px;
    position: absolute;
    left: 18px;
    top: 6px;

    &-checkmark {
      display: none;
    }
  }

  span {
    font-size: 16px;
  }
}

table {
  width: calc(100% + 6px);
  border-collapse: collapse;
}

th {
  text-align: left;
  text-align: start;
  font-weight: inherit;
}

th,
td {
  padding: 4px 0;
  border-bottom: 1px solid;

  &:last-child {
    text-align: right;
    text-align: end;
    padding-right: 6px;
  }
}

.header {
  height: 30px;
}

.menu {
  display: flex;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &-column {
    width: 25%;

    @include phone {
      flex: 1 1 auto;
      width: auto;

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        display: none;
      }
    }

    &:last-child {
      text-align: right;
      text-align: end;
    }
  }

  &-link {
    display: inline-block;
    padding: 6px;
    text-decoration: none;

    &.is-active {
      font-style: italic;
    }
  }
}

.page {
  padding: 6px;

  &--right {
    margin-left: 50%;

    @include phone {
      margin-left: 0;
    }
  }
}

.gallery {
  overflow: hidden;
  padding-bottom: 28.57142857%;
  margin-bottom: 26px;

  @include phone {
    display: none;
  }

  img {
    transition-property: transform;
    transform: translateX(100%);
    background: white;

    &.is-active {
      transform: none;
      z-index: 1;
    }

    &.move-left {
      transform: translateX(-100%);
      z-index: 1;
    }
  }
}

.projects {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 1fr 1fr;

  @include phone {
    display: block;
  }
}

.project-preview {
  display: block;
  margin-bottom: 4px;
  text-decoration: none;

  &:hover {
    .project-preview-image-element {
      filter: blur(8px);
    }
  }

  &-image {
    overflow: hidden;

    &-element {
      transform: scale(1.03);
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-image {
    width: 100%;
    height: auto;
    margin-bottom: 26px;

    &[data-width="sixty"] {
      width: 60vw;
    }

    &[data-width="half"] {
      width: calc(50vw - 3px);
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &-footer {
    margin: -26px 0 0;
    padding: 10px 2px 2px;
    flex-basis: 100%;
    display: flex;
    text-decoration: none;

    @include phone {
      padding-bottom: 20px;
    }

    &-label {
      width: 25%;

      @include phone {
        width: 50%;
      }
    }
  }
}

.product {
  display: flex;
  margin-bottom: 32px;
  scroll-margin-block: 30px;

  @include phone {
    display: block;
  }

  &-images {
    width: 50%;

    @include phone {
      width: auto;
      overflow: auto;
      display: flex;
      scroll-snap-type: x mandatory;
    }

    &.inactive {
      .product-image:first-child {
        display: block;
      }
    }
  }

  &-image {
    display: none;
    width: 100%;
    height: auto;

    @include phone {
      width: 100%;
      display: block;
      flex-shrink: 0;
      scroll-snap-align: center;
    }

    &[data-orientation="portrait"] {
      width: 60%;
    }

    &.is-active {
      display: block;
    }
  }

  &-text {
    padding: 2px 6px;
    width: 25%;

    @include phone {
      padding: 6px;
      width: auto;
    }

    h2 {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 1em;
    }
  }

  &-controls {
    margin-top: 1em;
  }
}

footer {
  padding: 6px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  z-index: 20;
  pointer-events: none;
  display: none;

  @include phone {
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &[data-column="3"] {
    right: 25%;
    padding-right: 24px;

    @include phone {
      right: 0;
      padding-right: 0;
      background: white;

      .modal-header-title {
        margin-left: 0;
        width: auto;
      }
    }

    .modal-body {
      padding: 6px;
      padding-left: 0;

      @include phone {
        padding-left: 6px;
      }
    }
  }

  &.is-visible {
    display: block;
  }

  &-header {
    &-title {
      padding: 6px;
      padding-left: 0;
      pointer-events: auto;
      display: inline-block;

      @include phone {
        display: block;
        padding-left: 6px;
      }

      &--clickable {
        @extend %clickable;
      }

      .is-active & {
        font-style: italic;
      }
    }
  }

  &-body {
    pointer-events: auto;
    background: white;
    flex: 1;
  }

  &-back {
    text-decoration: none;

    @include phone {
      position: absolute;
      right: 0;
      top: 0;
      padding: 6px;
    }
  }
}

.checkout {
  position: absolute;
}

.cart {
  &.is-visible {
    @include phone {
      display: flex;
      position: sticky;
      top: 30px;
      background: white;

      &.is-active {
        ~ .product,
        ~ footer {
          display: none;
        }
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);

    @include phone {
      max-height: calc(100vh - 60px);
    }
  }

  .modal-header-title {
    @include phone {
      text-align: right;
    }
  }

  &-header {
    &-title {
      &:after {
        content: ":";

        @include tablet {
          content: "";
        }
      }
    }

    &-count {
      @include tablet {
        &:before { content: "("; }
        &:after { content: ")"; }
      }
    }

    &-products {
      @include tablet {
        display: none;
      }
    }
  }

  &-items {
    flex: 1 1 0%;
    min-height: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
  }

  &-item {
    margin-bottom: 18px;
    flex-shrink: 0;

    &-photo {
      width: 100%;
      height: auto;
      vertical-align: middle;

      &[data-orientation="portrait"] {
        width: 60%;
      }

      &-ratio {
        [data-type="calendar"] & {
          padding-bottom: 69.76744186%;
        }

        [data-type="postcard"] [data-orientation="landscape"] & {
          padding-bottom: 70.94594595%;
        }

        [data-type="postcard"] [data-orientation="portrait"] & {
          padding-bottom: 140.95238094%;
        }
      }
    }

    &-text {
      padding: 6px 6px 6px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid;

      @include phone {
        padding-left: 6px;
      }
    }

    &-price {
      margin-left: 1em;
    }

    &-controls {
      padding: 6px 6px 6px 0;
      display: flex;
      justify-content: space-between;

      @include phone {
        padding-left: 6px;
      }

      &-remove {
        text-decoration: none;
      }
    }
  }

  &-footer {
    flex-shrink: 0;

    &-row {
      border-top: 1px solid;
      display: flex;
      justify-content: space-between;
      padding: 6px 6px 6px 0;

      @include phone {
        padding-left: 6px;
      }

      &--standout {
        font-style: italic;
      }
    }
  }
}

.confirmation {
  @include phone {
    position: absolute;
  }
}
