.easing-linear {
  transition-timing-function: linear; }

.easing-ease {
  transition-timing-function: ease; }

.easing-easeIn {
  transition-timing-function: ease-in; }

.easing-easeOut {
  transition-timing-function: ease-out; }

.easing-easeInOut {
  transition-timing-function: ease-in-out; }

.easing-easeInQuad {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

.easing-easeInCubic {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.easing-easeInQuart {
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.easing-easeInQuint {
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.easing-easeInSine {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

.easing-easeInExpo {
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035); }

.easing-easeInCirc {
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335); }

.easing-easeInBack {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

.easing-easeOutQuad {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.easing-easeOutCubic {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.easing-easeOutQuart {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.easing-easeOutQuint {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.easing-easeOutSine {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

.easing-easeOutExpo {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.easing-easeOutCirc {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }

.easing-easeOutBack {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.easing-easeInOutQuad {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.easing-easeInOutCubic {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

.easing-easeInOutQuart {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1); }

.easing-easeInOutQuint {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }

.easing-easeInOutSine {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

.easing-easeInOutExpo {
  transition-timing-function: cubic-bezier(1, 0, 0, 1); }

.easing-easeInOutCirc {
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.easing-easeInOutBack {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

@font-face {
  font-family: "Monument Grotesk";
  font-weight: 500;
  src: url(../fonts/MonumentGrotesk-Medium.woff2) format("woff2"), url(../fonts/MonumentGrotesk-Medium.woff) format("woff2"); }

@font-face {
  font-family: "Monument Grotesk";
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/MonumentGrotesk-MediumItalic.woff2) format("woff2"), url(../fonts/MonumentGrotesk-MediumItalic.woff) format("woff2"); }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.modal-header-title--clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5; }

html {
  font-size: 16px;
  font-family: "Monument Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p,
ol,
ul,
table {
  margin-top: 0;
  margin-bottom: 1.1em;
  max-width: 50vw; }
  p:last-child,
  ol:last-child,
  ul:last-child,
  table:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    p,
    ol,
    ul,
    table {
      max-width: 100%; } }

ol,
ul {
  padding-left: 1em; }

a {
  color: inherit; }
  a:hover {
    font-style: italic; }

.image-ratio,
.icon-ratio {
  position: relative; }
  .image-ratio img,
  .image-ratio svg,
  .icon-ratio img,
  .icon-ratio svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

svg {
  fill: currentColor;
  vertical-align: middle; }
  svg use {
    pointer-events: none; }

figcaption {
  padding: 2px; }

label {
  font-size: 13px;
  text-size-adjust: 100%; }

fieldset {
  border: none;
  margin: 0 0 32px; }

legend {
  margin-bottom: 4.5px; }

input,
button,
.button {
  appearance: none;
  border-radius: 999px;
  color: inherit;
  background: none;
  border: 1px solid;
  padding: 5px 12px;
  font-family: inherit;
  font-size: inherit; }

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 300px; }

input[type="number"] {
  width: 62px; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    opacity: 1; }

input[type="checkbox"],
input[type="radio"] {
  height: 31px;
  width: 53px; }

.honigpferd {
  position: absolute;
  left: -9999px;
  top: -99999px; }

button,
.button {
  cursor: pointer;
  background: black;
  color: white;
  padding: 5px 20px;
  text-decoration: none; }
  button:hover,
  .button:hover {
    font-style: italic; }

.form-group {
  margin-bottom: 9px; }
  .form-group label {
    display: block;
    margin-bottom: 1px; }
  .form-group button {
    margin-top: 10px; }

.form-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -4.5px;
  margin-top: 4.5px; }
  .form-grid .form-group {
    padding: 4.5px;
    flex-basis: 100%;
    margin-bottom: 0; }
    .form-grid .form-group[data-width="1/3"] {
      flex-basis: calc(100% / 3); }
    .form-grid .form-group[data-width="2/3"] {
      flex-basis: calc(100% / 3 * 2); }
    .form-grid .form-group input[type="text"],
    .form-grid .form-group input[type="email"],
    .form-grid .form-group input[type="tel"] {
      width: 100%; }

.form-controls {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .form-controls {
      margin-bottom: 32px; } }
  .form-controls a {
    text-decoration: none; }

.checkbox-replacement {
  position: relative;
  display: flex !important;
  align-items: center; }
  .checkbox-replacement input {
    margin-right: 9px; }
    .checkbox-replacement input:checked ~ .icon-checkmark {
      display: initial; }
  .checkbox-replacement .icon {
    width: 18px;
    position: absolute;
    left: 18px;
    top: 6px; }
    .checkbox-replacement .icon-checkmark {
      display: none; }
  .checkbox-replacement span {
    font-size: 16px; }

table {
  width: calc(100% + 6px);
  border-collapse: collapse; }

th {
  text-align: left;
  text-align: start;
  font-weight: inherit; }

th,
td {
  padding: 4px 0;
  border-bottom: 1px solid; }
  th:last-child,
  td:last-child {
    text-align: right;
    text-align: end;
    padding-right: 6px; }

.header {
  height: 30px; }

.menu {
  display: flex;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  .menu-column {
    width: 25%; }
    @media screen and (max-width: 767px) {
      .menu-column {
        flex: 1 1 auto;
        width: auto; }
        .menu-column:nth-child(2) {
          text-align: center; }
        .menu-column:nth-child(3) {
          display: none; } }
    .menu-column:last-child {
      text-align: right;
      text-align: end; }
  .menu-link {
    display: inline-block;
    padding: 6px;
    text-decoration: none; }
    .menu-link.is-active {
      font-style: italic; }

.page {
  padding: 6px; }
  .page--right {
    margin-left: 50%; }
    @media screen and (max-width: 767px) {
      .page--right {
        margin-left: 0; } }

.gallery {
  overflow: hidden;
  padding-bottom: 28.57142857%;
  margin-bottom: 26px; }
  @media screen and (max-width: 767px) {
    .gallery {
      display: none; } }
  .gallery img {
    transition-property: transform;
    transform: translateX(100%);
    background: white; }
    .gallery img.is-active {
      transform: none;
      z-index: 1; }
    .gallery img.move-left {
      transform: translateX(-100%);
      z-index: 1; }

.projects {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .projects {
      display: block; } }

.project-preview {
  display: block;
  margin-bottom: 4px;
  text-decoration: none; }
  .project-preview:hover .project-preview-image-element {
    filter: blur(8px); }
  .project-preview-image {
    overflow: hidden; }
    .project-preview-image-element {
      transform: scale(1.03);
      display: block;
      width: 100%;
      height: auto; }

.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .project-image {
    width: 100%;
    height: auto;
    margin-bottom: 26px; }
    .project-image[data-width="sixty"] {
      width: 60vw; }
    .project-image[data-width="half"] {
      width: calc(50vw - 3px); }
    .project-image img {
      width: 100%;
      height: auto;
      vertical-align: middle; }
  .project-footer {
    margin: -26px 0 0;
    padding: 10px 2px 2px;
    flex-basis: 100%;
    display: flex;
    text-decoration: none; }
    @media screen and (max-width: 767px) {
      .project-footer {
        padding-bottom: 20px; } }
    .project-footer-label {
      width: 25%; }
      @media screen and (max-width: 767px) {
        .project-footer-label {
          width: 50%; } }

.product {
  display: flex;
  margin-bottom: 32px;
  scroll-margin-block: 30px; }
  @media screen and (max-width: 767px) {
    .product {
      display: block; } }
  .product-images {
    width: 50%; }
    @media screen and (max-width: 767px) {
      .product-images {
        width: auto;
        overflow: auto;
        display: flex;
        scroll-snap-type: x mandatory; } }
    .product-images.inactive .product-image:first-child {
      display: block; }
  .product-image {
    display: none;
    width: 100%;
    height: auto; }
    @media screen and (max-width: 767px) {
      .product-image {
        width: 100%;
        display: block;
        flex-shrink: 0;
        scroll-snap-align: center; } }
    .product-image[data-orientation="portrait"] {
      width: 60%; }
    .product-image.is-active {
      display: block; }
  .product-text {
    padding: 2px 6px;
    width: 25%; }
    @media screen and (max-width: 767px) {
      .product-text {
        padding: 6px;
        width: auto; } }
    .product-text h2 {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 1em; }
  .product-controls {
    margin-top: 1em; }

footer {
  padding: 6px; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  z-index: 20;
  pointer-events: none;
  display: none; }
  @media screen and (max-width: 767px) {
    .modal {
      position: relative;
      width: 100%;
      height: 100%;
      flex-direction: column; } }
  .modal[data-column="3"] {
    right: 25%;
    padding-right: 24px; }
    @media screen and (max-width: 767px) {
      .modal[data-column="3"] {
        right: 0;
        padding-right: 0;
        background: white; }
        .modal[data-column="3"] .modal-header-title {
          margin-left: 0;
          width: auto; } }
    .modal[data-column="3"] .modal-body {
      padding: 6px;
      padding-left: 0; }
      @media screen and (max-width: 767px) {
        .modal[data-column="3"] .modal-body {
          padding-left: 6px; } }
  .modal.is-visible {
    display: block; }
  .modal-header-title {
    padding: 6px;
    padding-left: 0;
    pointer-events: auto;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      .modal-header-title {
        display: block;
        padding-left: 6px; } }
    .is-active .modal-header-title {
      font-style: italic; }
  .modal-body {
    pointer-events: auto;
    background: white;
    flex: 1; }
  .modal-back {
    text-decoration: none; }
    @media screen and (max-width: 767px) {
      .modal-back {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px; } }

.checkout {
  position: absolute; }

@media screen and (max-width: 767px) {
  .cart.is-visible {
    display: flex;
    position: sticky;
    top: 30px;
    background: white; }
    .cart.is-visible.is-active ~ .product,
    .cart.is-visible.is-active ~ footer {
      display: none; } }

.cart .modal-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px); }
  @media screen and (max-width: 767px) {
    .cart .modal-body {
      max-height: calc(100vh - 60px); } }

@media screen and (max-width: 767px) {
  .cart .modal-header-title {
    text-align: right; } }

.cart-header-title:after {
  content: ":"; }
  @media screen and (max-width: 1024px) {
    .cart-header-title:after {
      content: ""; } }

@media screen and (max-width: 1024px) {
  .cart-header-count:before {
    content: "("; }
  .cart-header-count:after {
    content: ")"; } }

@media screen and (max-width: 1024px) {
  .cart-header-products {
    display: none; } }

.cart-items {
  flex: 1 1 0%;
  min-height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  scroll-behavior: smooth; }

.cart-item {
  margin-bottom: 18px;
  flex-shrink: 0; }
  .cart-item-photo {
    width: 100%;
    height: auto;
    vertical-align: middle; }
    .cart-item-photo[data-orientation="portrait"] {
      width: 60%; }
    [data-type="calendar"] .cart-item-photo-ratio {
      padding-bottom: 69.76744186%; }
    [data-type="postcard"] [data-orientation="landscape"] .cart-item-photo-ratio {
      padding-bottom: 70.94594595%; }
    [data-type="postcard"] [data-orientation="portrait"] .cart-item-photo-ratio {
      padding-bottom: 140.95238094%; }
  .cart-item-text {
    padding: 6px 6px 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid; }
    @media screen and (max-width: 767px) {
      .cart-item-text {
        padding-left: 6px; } }
  .cart-item-price {
    margin-left: 1em; }
  .cart-item-controls {
    padding: 6px 6px 6px 0;
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .cart-item-controls {
        padding-left: 6px; } }
    .cart-item-controls-remove {
      text-decoration: none; }

.cart-footer {
  flex-shrink: 0; }
  .cart-footer-row {
    border-top: 1px solid;
    display: flex;
    justify-content: space-between;
    padding: 6px 6px 6px 0; }
    @media screen and (max-width: 767px) {
      .cart-footer-row {
        padding-left: 6px; } }
    .cart-footer-row--standout {
      font-style: italic; }

@media screen and (max-width: 767px) {
  .confirmation {
    position: absolute; } }
